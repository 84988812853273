<template>
  <div>
    <v-dialog v-model="dialog" max-width="550px">
      <v-card elevation="10" class="">
        <v-toolbar dense color="#003d6a" elevation="0" dark>
          <v-toolbar-title>Bildirim Gönder</v-toolbar-title>
        </v-toolbar>

        <div class="pa-5">
          <v-text-field
            outlined
            dense
            placeholder="Başlık"
            label="Başlık"
            v-model="form.title"
          ></v-text-field>
          <v-textarea
            outlined
            dense
            placeholder="Açıklama"
            label="Açıklama"
            v-model="form.description"
          ></v-textarea>
          <RoleProvider slug="NOTIFICATION_CREATE">
            <div slot-scope="{ canItPass }">
              <div class="w-full d-flex justify-end">
                <v-btn
                  dense
                  :disabled="sendBtnDisabled"
                  color="success"
                  @click="sendNotifications('first')"
                  style="width: 200px"
                >
                  Gönder
                </v-btn>
              </div>
            </div>
          </RoleProvider>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="detailDialog" max-width="550px">
      <v-card elevation="10" class="">
        <v-toolbar dense color="#003d6a" elevation="0" dark>
          <v-toolbar-title>Bildirim Gönder</v-toolbar-title>
        </v-toolbar>

        <div class="pa-5">
          <v-text-field
            outlined
            dense
            placeholder="Başlık"
            label="Başlık"
            v-model="itemDetail.title"
          ></v-text-field>
          <v-textarea
            outlined
            dense
            placeholder="Açıklama"
            label="Açıklama"
            v-model="itemDetail.description"
          ></v-textarea>
          <RoleProvider slug="NOTIFICATION_CREATE">
            <div slot-scope="{ canItPass }">
              <div class="w-full d-flex justify-end">
                <v-btn
                  dense
                  :disabled="sendBtnDisabled"
                  color="success"
                  @click="sendNotifications('again')"
                  style="width: 200px"
                >
                  Tekrar Gönder
                </v-btn>
              </div>
            </div>
          </RoleProvider>
        </div>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-card elevation="10">
          <v-toolbar dense color="#003d6a" elevation="0" dark>
            <v-toolbar-title>Bildirimler</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="d-flex justify-content-end" style="min-width: 550px">
              <v-btn
                outlined
                small
                @click="dialog = true"
                class="py-2 px-3 font-weight-bold"
              >
                <font-awesome-icon
                  :icon="['fas', 'plus']"
                  class="mr-2"
                />Bildirim Gönder
              </v-btn>
            </div>
          </v-toolbar>
          <CCardBody class="p-0">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-alpine"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              @rowDoubleClicked="onRowDoubleClick"
            />
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { RoleProvider } from "../../provider";
import moment from "moment";

export default {
  name: "Notifications",
  components: {
    AgGridVue,
    RoleProvider,
  },

  data() {
    return {
      form: {
        title: "",
        description: "",
      },
      dialog: false,
      detailDialog: false,
      sendBtnDisabled: false,
      columnDefs: [
        {
          field: "title",
          headerName: "Başlık",
        },
        {
          field: "description",
          headerName: "Açıklama",
          width: 400,
          suppressSizeToFit: true,
        },
        {
          field: "createdAt",
          headerName: "Oluşturma Tarihi",
          cellRenderer: (params) => {
            return moment(new Date(params.value)).format("DD.MM.YYYY - HH:mm");
          },
        },
        {
          field: "createdBy",
          headerName: "Oluşturan Kişi",
          valueFormatter: (params) => params.value.fullname,
        },
        {
          field: "createdBy",
          headerName: "Oluşturan Kişi",
          valueFormatter: (params) => params.value.email,
          suppressSizeToFit: true,
          width: 300,
        },
        {
          field: "count",
          headerName: "İletilen Cihaz Sayısı",
          cellRenderer: (params) => {
            return params.value || "Bilinmiyor";
          },
          suppressSizeToFit: true,
          width: 175,
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      rowData: [],
      itemDetail: {},
    };
  },
  watch: {
    detailDialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.getNotificatons();
  },

  methods: {
    // onGridReady(params) {
    //   this.gridApi = params.api;
    //   this.gridColumnApi = params.columnApi;
    //   params.api.sizeColumnsToFit();
    // },
    close() {
      this.itemDetail = {};
    },
    onRowDoubleClick(params) {
      this.itemDetail = Object.assign({}, params.node.data);
      this.detailDialog = true;
    },
    async getNotificatons() {
      try {
        let response = await axios.get(
          process.env.VUE_APP_API_URL + `admin/notification-list`
        );
        if (response.data.result) {
          this.rowData = response.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async sendNotifications(type) {
      try {
        let data = {};
        if (type === "again") {
          data.title = this.itemDetail.title;
          data.description = this.itemDetail.description;
        } else {
          data = this.form;
        }

        this.sendBtnDisabled = true;
        let response = await axios.post(
          process.env.VUE_APP_API_URL + "admin/test/notification",
          {
            data,
          }
        );
        if (response.data.status) {
          this.form = {
            title: "",
            description: "",
          };
          this.sendBtnDisabled = false;
          this.dialog = false;
          this.$store.dispatch({
            type: "successSwal",
            text: "Bildirim Başarılı Bir Şekilde Gönderildi",
            refresh: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.ag-theme-balham {
  width: 100%;
  height: 500px;
}
</style>
